import { clsx } from "clsx"
import { twMerge } from "tailwind-merge";
const cn = function (...inputs) {
    return twMerge(clsx(inputs))
}

const calcInitialZoom = function (canvasWidth, canvasHeight, headerheight = 70) {
    // Get the viewport width and height
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight - headerheight; // Subtract the header height

    // Calculate the width and height ratios
    const widthRatio = viewportWidth / canvasWidth;
    const heightRatio = viewportHeight / canvasHeight;

    // Use the smaller ratio to ensure the canvas fits within the viewport and maintains the aspect ratio
    const zoomLevel = Math.min(widthRatio, heightRatio);

    return zoomLevel;
}


export { cn, calcInitialZoom }
